<template>
 <div class="MainTol">
   <div class="view" :style="listheight">
     <router-view></router-view>
   </div>
   <Botton class="Bottons"></Botton>
 </div>
</template>

<script>
import Botton from '@/views/Main/Com/Botton.vue'
export default {
  data(){
    return{
      listheight:''
    }
  },
  components:{
    Botton
  },
  mounted() {
    this.init()
  },
  methods:{
    init(){
      let listheight = document.documentElement.clientHeight - 70
      listheight = 'height:' + listheight + 'px'
      this.listheight = listheight
    }
  }
}
</script>

<style scoped lang="scss">
.MainTol{
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
