<template>
  <div class="Bottom">
    <router-link to="/Main/Home">
      <div class="blcok">
        <div class="icon home"></div>
        <div class="text">首页</div>
      </div>
    </router-link>
    <router-link to="/Main/Object">
      <div class="blcok">
        <div class="icon Wallet"></div>
        <div class="text">项目列表</div>
      </div>
    </router-link>
    <router-link to="/Exchange">
      <div class="blcok">
        <div class="icon Cart"></div>
        <div class="text">兑换商场</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {}
</script>


<style scoped lang="scss">
.Bottom {
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 70px;
  background-color: #15171d;
  display: flex;
  justify-content: space-around;

  .blcok {
    width: 70px;
    height: 70px;
    position: relative;

    .icon {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 20px;
      top: 10px;
      background-size: 100% 100%;
    }

    .home{
      background-image: url("../../../assets/image/Home.png");
    }

    .Cart{
      background-image: url("../../../assets/image/Cart.png");
    }

    .Wallet{
      background-image: url("../../../assets/image/Wallet.png");
    }

    .text {
      font-size: 13px;
      color: #f3f2f5;
      text-align: center;
      width: 100%;
      height: 30px;
      position: absolute;
      top: 45px;
    }
  }
}
</style>